function ErrorView() {
  return (
    <html>
      <body>
        <header className="c-header c-header--error-page">
          <div className="grid-container">
            <div className="c-logo">
              <img src="/images/geberit_logo.svg" alt="GEBERIT" title="GEBERIT" />
            </div>
          </div>
        </header>
        <main className="error-page">
          <div className="c-error-page" style={{ backgroundImage: `url(/images/error/404.jpg)` }}>
            <div className="grid-container">
              <div className="c-error-page__content">
                <span>HTTP 500</span>
                <h1 className="h2">Internal Server Error</h1>
                <p>
                  Services aren&apos;t all on track, so the site is temporarily unavailable. We are
                  working on it and will be back shortly.
                </p>
              </div>
            </div>
          </div>
        </main>
      </body>
    </html>
  );
}

export default ErrorView;
